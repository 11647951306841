import { ElementRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ChatSectionService } from '@app/chat/services/chat-section.service';
import { NotificationsService } from 'angular2-notifications';
import { now } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { delay, filter, map, tap } from 'rxjs/operators';

import { ChatContactsOnlyFilterData } from '@app/chat/chat-contacts-only-filter/services';
import type { CounterData, LoadedMessagesOffset, RoomsBySections, SocketResponseRoomData } from '@app/chat/models';
import {
  ChatLastMessageDateThemeInGroups,
  ChatMessage,
  ChatMessagesRoomStore,
  ChatMessagesStore,
  ChatRoom,
  ChatSection,
  ChatUserTree,
} from '@app/chat/models/chat.model';
import { LocalStorageService } from '@app/services/local-storage.service';
import { StatusesEnum } from '@app/shared/constants/statuses.constants';
import { Company } from '@app/shared/models/company.model';
import { User } from '@app/shared/models/user.model';
import { AuthService } from '@app/shared/services/auth.service';
import { UserTypes } from '@app/shared/types/user.types';
import { deepCopy } from '@app/shared/utils';
import { CHAT_STATE_STORAGE_KEY, CONTACTS_FILTER_STORAGE_KEY, PINNED_TABS_STORAGE_KEY } from '../constants';
import {
  CHAT_SECTIONS,
  ChatSectionsEnum,
  ContactsSortingNames,
  GroupSortingNames,
  TechTypeEnum,
  TradeRoomsTypes,
} from '../constants/chat-sections.constants';

interface IGroupsThemes {
  themes: ChatRoom;
  groups: ChatRoom;
}

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  userId: number;
  userRootFolderId: number;
  userType: UserTypes;

  private quotedMessage: BehaviorSubject<ChatMessage> = new BehaviorSubject<ChatMessage>(null);
  public quotedMessage$ = this.quotedMessage.asObservable();

  public contacts: any = {};
  contactsChanged: Subject<any> = new Subject<any>();

  userTree: ChatUserTree | null = null;
  userTreeChanged: Subject<any> = new Subject<any>();
  // для выравнивания карточек в ряд в независимости от уровня вложенности
  private userTreeExpanded: Array<number> = [];
  private tradeExpanded: Array<string> = [];

  public groups: any = {};
  groupsChanged: Subject<any> = new Subject<any>();
  groupExpanded: Subject<any> = new Subject<any>();
  public themes: any = {};
  themesChanged: Subject<any> = new Subject<any>();
  private contactSelected: ChatRoom = { id: null } as ChatRoom;
  private groupSelected: ChatRoom = { id: null } as ChatRoom;
  private themeSelected: ChatRoom = { id: null } as ChatRoom;

  contactSelectedChanged: BehaviorSubject<ChatRoom> = new BehaviorSubject<ChatRoom>({} as ChatRoom);
  groupSelectedChanged: Subject<ChatRoom> = new Subject<ChatRoom>();
  themeSelectedChanged: Subject<ChatRoom> = new Subject<ChatRoom>();

  private isTabsInit = true;
  private tabs: any[] = [];
  tabsChanged: Subject<any[]> = new Subject<any[]>();

  public messages: ChatMessagesStore = {} as ChatMessagesStore;
  messagesChanged: Subject<ChatMessagesStore> = new Subject<ChatMessagesStore>();
  focusChanged: Subject<boolean> = new Subject<boolean>();

  isEditingGroupOrTheme: boolean = false;
  private editingGroupOrThemeObject = {} as ChatRoom;
  isEditingGroupOrThemeChanged: Subject<boolean> = new Subject<boolean>();

  contactsFilter: string = '';
  contactsFilterChanged: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  isEditingContactsOnlyFilter: boolean = false;
  isEditingContactsOnlyFilterChanged: Subject<boolean> = new Subject<boolean>();
  contactsOnlyFilter: any;
  contactsOnlyFilters: Record<string, ChatContactsOnlyFilterData> = {};
  contactsOnlyFilterChanged: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  contactsOnlyFiltersState$ = new BehaviorSubject<Record<string, boolean>>({});

  chatLoading = new BehaviorSubject<boolean>(true);
  chatLoaded = new BehaviorSubject<boolean>(false);

  public companies: {
    [key: number]: Company;
  } = {};
  public rooms: RoomsBySections = {};
  public users: {
    [key: number]: User;
  } = {};
  usersChanged: Subject<any> = new Subject<any>();

  currentTechType = new BehaviorSubject<TechTypeEnum | null>(null);

  private isDutyTso = new BehaviorSubject<boolean>(false);

  isDutyTso$ = this.isDutyTso.asObservable();

  private removedTsoId = new Subject<number>();
  removedTsoId$ = this.removedTsoId.asObservable();

  techCounter = new BehaviorSubject({
    [TechTypeEnum.MY_TECH]: 0,
    [TechTypeEnum.MY_USERS]: 0,
    [TechTypeEnum.ALL_USERS]: 0,
  });

  techCounter$ = this.techCounter.pipe(
    map((counter) => {
      return {
        [TechTypeEnum.MY_TECH]: counter[TechTypeEnum.MY_TECH] <= 10 ? counter[TechTypeEnum.MY_TECH] : '10+',
        [TechTypeEnum.MY_USERS]: counter[TechTypeEnum.MY_USERS] <= 10 ? counter[TechTypeEnum.MY_USERS] : '10+',
        [TechTypeEnum.ALL_USERS]: counter[TechTypeEnum.ALL_USERS] <= 10 ? counter[TechTypeEnum.ALL_USERS] : '10+',
      };
    })
  );

  widgetMessageCounter: number = 0;
  widgetMessageCounterChanged: Subject<number> = new Subject<number>();
  scrollToTabChanged: Subject<ElementRef> = new Subject<ElementRef>();

  statusesEnum = StatusesEnum;

  isCollapsed$ = new BehaviorSubject<boolean>(false);
  isChatOpen$ = new BehaviorSubject<boolean>(false);

  pinnedTabs$ = new BehaviorSubject<ChatRoom[]>([]);
  toggleTabPinned$ = new Subject<ChatRoom>();

  usersForContactsFilter$ = new BehaviorSubject<User[] | ChatRoom[]>(null);

  readonly chatDestroyed$ = new Subject();

  get pinnedTabs() {
    const currentTechType = this.currentTechType.value;

    const pinnedTabs = this.pinnedTabs$.value
      .filter((tab) => tab && tab.room_id)
      .map((tab) => {
        if (tab.tech_section_type) {
          this.currentTechType.next(tab.tech_section_type);
        }

        const newTab = this.findChatRoomById(tab.room_id);

        if (newTab) {
          newTab.isPinned = true;

          return newTab;
        }
      });

    this.currentTechType.next(currentTechType);

    return pinnedTabs.filter((tab) => tab && tab.room_id);
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private notify: NotificationsService,
    private localStorageService: LocalStorageService,
    private chatSectionService: ChatSectionService
  ) {
    this.userId = +this.authService.user_id;
    this.userType = this.authService.user_type;

    this.toggleTabPinned$
      .pipe(
        tap((tab) => {
          const currentTabs = this.pinnedTabs.filter(Boolean);
          const index = currentTabs.findIndex((item) => item.room_id === tab?.room_id);

          if (index !== -1) {
            currentTabs.splice(index, 1);
          } else {
            currentTabs.push(tab);
          }

          this.pinnedTabs$.next([...currentTabs]);

          this.tabsChanged.next(this.getTabs());

          this.savePinnedTabsToLocalStorage();
        })
      )
      .subscribe();

    this.chatLoaded.subscribe((isLoaded) => {
      if (isLoaded) {
        this.loadPinnedTabsFromLocalStorage();

        const savedChatState = this.localStorageService.getItem(CHAT_STATE_STORAGE_KEY);

        if (savedChatState && savedChatState.isOpen) {
          if (savedChatState.currentTechType) {
            this.currentTechType.next(savedChatState.currentTechType);
          }

          this.openChat(savedChatState.roomId);
        }

        this.loadContactsFiltersFromLocalStorage();
      }
    });

    this.authService.logoutSubject$.subscribe(() => this.closeChat());
  }

  public clearContactSelected(): void {
    this.contactSelected = { id: null } as ChatRoom;
    this.contactSelectedChanged.next(Object.assign({}, this.contactSelected));
  }

  scrollMeToTab(elem: ElementRef) {
    this.scrollToTabChanged.next(elem);
  }

  openChat(roomId?: string) {
    this.isEditingGroupOrTheme = false;

    this.isChatOpen$.next(true);

    if (roomId) {
      this.setRoomSelected(this.findChatRoomById(roomId));
    }

    this.updateLocalStorageChatState();
  }

  closeChat() {
    this.isEditingGroupOrTheme = false;
    this.contactsFilterChanged.next(null);
    this.isChatOpen$.next(false);

    this.updateLocalStorageChatState();
    this.contactsOnlyFiltersState$.next({});
  }

  // filter contacts groups themes
  filterContactsGroupsThemes(searchText: string) {
    this.contactsFilter = searchText ? searchText.toLowerCase().trim() : '';
    this.contactsFilterChanged.next(this.contactsFilter);
  }

  // filter contacts groups themes end

  // filter only contacts
  toggleContactsOnlyFilter(value: boolean, filterName?: string) {
    this.isEditingContactsOnlyFilter = value;
    this.isEditingContactsOnlyFilterChanged.next(this.isEditingContactsOnlyFilter);
    this.contactsOnlyFiltersState$.next({ ...this.contactsOnlyFiltersState$.value, [filterName]: value });
  }

  getContactsOnlyFilter(filterName?: string) {
    return filterName ? this.contactsOnlyFilters[filterName] : this.contactsOnlyFilter;
  }

  setContactsOnlyFilter(value, filterName?: string) {
    this.contactsOnlyFilter = value;
    this.contactsOnlyFilters[filterName] = value;
    this.contactsOnlyFilterChanged.next(this.contactsOnlyFilters);
    this.saveContactsFiltersToLocalStorage();
  }

  // filter only contacts end

  // counter
  updateCounters(countersData: CounterData[]) {
    Object.keys(CHAT_SECTIONS).forEach((k) => {
      const chatSectionName = CHAT_SECTIONS[k].name;

      countersData.forEach((counterData) => {
        ['contacts', 'groups', 'themes'].forEach((key) => {
          this[key][chatSectionName] = this[key][chatSectionName] || {};
          // Доработать для тех раздела +

          if (chatSectionName === ChatSectionsEnum.TECH) {
            if (counterData.for_duty_tso) {
              const chatRoomAllUser = this[key][chatSectionName]?.[TechTypeEnum.ALL_USERS]?.[counterData.room_id];
              if (!chatRoomAllUser) {
                return;
              }
              this.updateTechCounters(counterData, chatRoomAllUser, TechTypeEnum.ALL_USERS);
              this.updateChatRoomCounters(counterData, chatRoomAllUser, k);
            } else if (counterData.for_tso) {
              const chatRoomMyUser = this[key][chatSectionName]?.[TechTypeEnum.MY_USERS]?.[counterData.room_id];
              if (chatRoomMyUser) {
                this.updateTechCounters(counterData, chatRoomMyUser, TechTypeEnum.MY_USERS);
                this.updateChatRoomCounters(counterData, chatRoomMyUser, k);
              }
            } else {
              const chatRoomMyTech = this[key][chatSectionName]?.[TechTypeEnum.MY_TECH]?.[counterData.room_id];

              if (chatRoomMyTech) {
                this.updateTechCounters(counterData, chatRoomMyTech, TechTypeEnum.MY_TECH);
                this.updateChatRoomCounters(counterData, chatRoomMyTech, k);
              }
            }
          }

          if (chatSectionName !== ChatSectionsEnum.TECH) {
            const chatRoomById = this[key][chatSectionName][counterData.room_id];
            if (!chatRoomById) return;
            this.updateChatRoomCounters(counterData, chatRoomById, k);
          }

          this[`${key}Changed`].next(Object.assign({}, this[key][this.chatSectionService.chatSectionSelected.name]));
        });
      });
    });
    this.widgetMessageCounterChanged.next(this.widgetMessageCounter);
    this.isTabsInit = false;
  }

  private updateTechCounters(counterData: CounterData, chatRoomById, key: TechTypeEnum) {
    const techCounters = this.techCounter.getValue();
    chatRoomById.counter = chatRoomById.counter ?? 0;
    techCounters[key] -= chatRoomById.counter;
    techCounters[key] += counterData.count;

    this.techCounter.next({ ...techCounters });
  }

  private updateChatRoomCounters(counterData: CounterData, chatRoomById, k: string) {
    // chatRoomById - прошлый counter, counterData - новый counter (непрочитанных было 3 стало 2:  -3 +2
    if (chatRoomById.counter) {
      this.chatSectionService.chatSections[k].counter -= chatRoomById.counter;
      this.widgetMessageCounter -= chatRoomById.counter;
    }
    this.chatSectionService.chatSections[k].counter += counterData.count;
    this.widgetMessageCounter += counterData.count;

    chatRoomById.counter = counterData.count;
    chatRoomById.last_read_message_id = counterData.last_read_message_id;
    chatRoomById.first_not_read_message_creation_date_in_chat =
      counterData.first_not_read_message_creation_date_in_chat;
    chatRoomById.last_read_message_date_read = counterData.last_read_message_date_read;

    if (this.isTabsInit) {
      if (
        chatRoomById.tech_section_type !== TechTypeEnum.ALL_USERS &&
        chatRoomById.tech_section_type !== TechTypeEnum.MY_USERS
      ) {
        this.updateTabs(chatRoomById);
      }
    } else {
      // if (
      //   chatRoomById.tech_section_type !== TechTypeEnum.ALL_USERS &&
      //   chatRoomById.tech_section_type !== TechTypeEnum.MY_USERS
      // ) {
      this.updateTabCounters(chatRoomById);
      // }
    }
  }

  updateWidgetAndSectionMessageCounter() {
    this.widgetMessageCounter = 0;

    Object.keys(CHAT_SECTIONS).forEach((sectionKey) => {
      const chatSectionName = CHAT_SECTIONS[sectionKey].name;
      let sectionCounter = 0;

      ['contacts', 'groups', 'themes'].forEach((key) => {
        const sectionRooms = this[key]?.[chatSectionName];
        if (!sectionRooms) return;
        // Доработать для тех раздела +
        if (chatSectionName !== ChatSectionsEnum.TECH) {
          Object.values(sectionRooms).forEach((chatRoom: ChatRoom) => {
            const roomCounter = chatRoom?.counter || 0;
            this.widgetMessageCounter += roomCounter;
            sectionCounter += roomCounter;
          });
        }
        if (chatSectionName === ChatSectionsEnum.TECH) {
          Object.keys(sectionRooms).forEach((techType) => {
            Object.values(sectionRooms[techType]).forEach((chatRoom: ChatRoom) => {
              const roomCounter = chatRoom?.counter || 0;
              this.widgetMessageCounter += roomCounter;
              sectionCounter += roomCounter;
            });
          });
        }
      });

      this.chatSectionService.chatSections[sectionKey].counter = sectionCounter;
    });

    this.widgetMessageCounterChanged.next(this.widgetMessageCounter);
  }

  // counter end

  // tabs
  getTabs() {
    return this.tabs.slice();
  }

  private updateTechTabCounters(newTab: ChatRoom, currentTab: ChatRoom, tabIndex: number) {
    // В тех чате Обновляем только счетчики у текущей табы.
    // Смена табов происходит только при клике на соответсвующую группу тему или контакт
    if (currentTab.tech_section_type === newTab.tech_section_type && this.tabs[tabIndex]) {
      this.tabs[tabIndex].counter = newTab.counter ?? 0;
      this.tabsChanged.next([...this.tabs]);
      return;
    }
  }

  updateTabCounters(newTab: ChatRoom) {
    if (newTab.counter === 0 && this.contactSelected?.room_id !== newTab.room_id) {
      this.deleteTab(newTab);
      return;
    }

    const currentTab = this.tabs.find((tab) => tab.room_id === this.contactSelected?.room_id);
    const tabIndex = this.tabs.findIndex((tab) => tab.room_id === newTab.room_id);

    if (currentTab?.tech_section_type && newTab.tech_section_type && tabIndex !== -1) {
      this.updateTechTabCounters(newTab, currentTab, tabIndex);
      return;
    }

    if (tabIndex === -1) {
      if (newTab.tech_section_type === TechTypeEnum.ALL_USERS || newTab.tech_section_type === TechTypeEnum.MY_USERS) {
        return;
      }
      if (!currentTab) {
        this.tabs.push(newTab);
      } else {
        this.tabs = this.tabs.filter((tab) => tab.room_id !== currentTab.room_id);
        this.tabs.push(newTab, currentTab);
      }
    } else {
      this.tabs[tabIndex] = newTab;
    }

    this.tabsChanged.next([...this.tabs]);
  }

  updateTabs(newTab: ChatRoom) {
    if (!newTab) {
      return;
    }

    if (!this.tabs.some((tab) => tab.room_id === newTab.room_id)) {
      this.tabs.push(newTab);
    }

    if (newTab.tech_section_type) {
      // Заменить одну тех табу на другую, у них одинаковый room_id
      const tabIndex = this.tabs.findIndex((tab) => tab.room_id === newTab.room_id);
      if (tabIndex !== -1) {
        this.tabs[tabIndex] = newTab;
      }
    }

    const unreadTabs = this.tabs
      .filter(
        (tab) =>
          tab?.counter &&
          tab?.first_not_read_message_creation_date_in_chat &&
          tab.tech_section_type !== TechTypeEnum.ALL_USERS &&
          tab.tech_section_type !== TechTypeEnum.MY_USERS
      )
      .sort(
        (a, b) =>
          new Date(a.first_not_read_message_creation_date_in_chat).getTime() -
          new Date(b.first_not_read_message_creation_date_in_chat).getTime()
      );

    /* todo: как будут доделаны группы, проверить правильность выбора текущей вкладки,{contact/theme/group}Selected
     * на данный момент, не зависимо от выбора {contact/theme/group} это всё - contactSelected.
     * */
    const currentTab = this.tabs.find((tab) => tab.room_id === this.contactSelected?.room_id);

    this.tabs =
      currentTab && !unreadTabs.some((tab) => tab.room_id === currentTab.room_id)
        ? [...unreadTabs, currentTab]
        : [...unreadTabs];

    this.tabsChanged.next(this.tabs);
  }

  updateTabsData() {
    this.tabs = this.tabs
      .filter(
        (tab) =>
          tab?.counter ||
          tab.room_id === this.contactSelected?.room_id ||
          tab.tech_section_type !== TechTypeEnum.ALL_USERS ||
          tab.tech_section_type !== TechTypeEnum.MY_USERS
      )
      .map((currentTab) => {
        for (const key of ['contacts', 'groups', 'themes']) {
          // Доработать для тех раздела +
          let updatedTab = null;
          if (currentTab.section.name !== ChatSectionsEnum.TECH) {
            updatedTab = this[key][currentTab.section.name]?.[currentTab.room_id];
          }
          if (currentTab.section.name === ChatSectionsEnum.TECH) {
            updatedTab = this[key][currentTab.section.name]?.[currentTab.tech_section_type]?.[currentTab.room_id];
          }
          if (updatedTab) return updatedTab;
        }
        return currentTab;
      });
    this.tabsChanged.next(this.tabs);
  }

  deleteTab(delTab) {
    this.tabs = this.tabs.filter((tab) => tab.room_id !== delTab.room_id);
    this.pinnedTabs$.next(this.pinnedTabs.filter((tab) => tab.room_id !== delTab.room_id));
    this.tabsChanged.next(this.tabs.slice());
  }

  removeTabsBySection(sectionName) {
    this.clearContactSelected();
    this.tabs = this.tabs.filter((tab) => tab.section.name !== sectionName);
    this.tabsChanged.next(this.tabs.slice());
  }

  // tabs end

  // editing theme or group object
  getEditingGroupOrThemeObject(): ChatRoom {
    return Object.assign({}, this.editingGroupOrThemeObject);
  }

  setEditingGroupOrThemeObject(object: ChatRoom) {
    this.editingGroupOrThemeObject = object;
  }

  resetEditingGroupOrThemeObject() {
    this.editingGroupOrThemeObject = {} as ChatRoom;
  }

  // editing theme or group object end

  getMessagesRoomStore(room_id: string): ChatMessagesRoomStore {
    return (
      this.messages[room_id] ||
      ({
        list: [],
        count: 0,
      } as ChatMessagesRoomStore)
    );
  }

  setQuotedMessage(message: ChatMessage) {
    this.quotedMessage.next(message);
  }

  updateMessages(message: ChatMessage) {
    this.contacts[this.chatSectionService.chatSectionSelected.name] =
      this.contacts[this.chatSectionService.chatSectionSelected.name] || [];

    if (!this.messages[message.room_id]) {
      this.messages[message.room_id] = {
        list: [],
        count: 0,
      } as ChatMessagesRoomStore;
    }

    if (this.messages[message.room_id].list.some((msg) => msg.id === message.id)) {
      return;
    }

    if (!this.messages[message.room_id]?.offsets?.bottom) {
      // Доработать для тех раздела +
      Object.keys(this.groups).forEach((section) => {
        if (section !== ChatSectionsEnum.TECH && this.groups[section][message.room_id]) {
          this.groups[section][message.room_id].last_message_creation_date_in_chat = message.created_at;
        }
        if (section === ChatSectionsEnum.TECH) {
          Object.values(this.groups[section]).forEach((tech_type) => {
            if (tech_type[message.room_id]) {
              tech_type[message.room_id].last_message_creation_date_in_chat = message.created_at;
            }
          });
        }
      });

      if (!message.author) {
        message.author = this.users[message.author_id];
      }
      if (!message.author) {
        console.warn(`нет пользователя в users с ID: ${message.author_id}`);
      }
      this.messages[message.room_id].list.push(message);

      this.contactSelected.last_read_message_id ||= message.id;
    }

    this.messagesChanged.next(Object.assign({}, this.messages));
  }

  deleteMessage(deleteMessage: ChatMessage) {
    this.messages[deleteMessage.room_id].list.forEach((message) => {
      if (message.id === deleteMessage.id) {
        message.status = this.statusesEnum.IN_ARCHIVE;
        message.content = 'Сообщение удалено';
        message.parent = null;
        message.parent_id = null;
        message.attached_files = [];
        message.initial_author = null;
      }
      if (deleteMessage.id === this.quotedMessage.value?.id) {
        this.quotedMessage.next(message);
      }
    });
  }

  setMessages(messages: ChatMessage[], room_id: string, offsets: LoadedMessagesOffset) {
    this.contacts[this.chatSectionService.chatSectionSelected.name] =
      this.contacts[this.chatSectionService.chatSectionSelected.name] || [];

    if (!this.messages[room_id]) {
      this.messages[room_id] = {
        list: [],
      } as ChatMessagesRoomStore;
    }

    const existingIds = new Set(this.messages[room_id].list.map((msg) => msg.id));

    const newMessages = messages.filter((message) => {
      if (existingIds.has(message.id)) return false;

      // Для тех разделов минимальную информацию присылать в сокете
      if (!message.author) {
        message.author = this.users[message.author_id];
      }

      if (!message.author) {
        console.warn(`нет пользователя в users с ID: ${message.author_id}`);
      }
      return true;
    });

    const isMessagesPrepend = this.messages[room_id].offsets?.top && offsets.top < this.messages[room_id].offsets?.top;
    if (isMessagesPrepend) {
      this.messages[room_id].list.unshift(...newMessages.reverse());
    } else {
      this.messages[room_id].list.push(...newMessages.reverse());
    }

    this.messages[room_id].offsets = offsets;
    this.messagesChanged.next(Object.assign({}, this.messages));
  }

  getMessagesListByRoomId(room_id: string): ChatMessage[] {
    const messages = this.messages[room_id]?.list;
    return messages ? [...messages] : [];
  }

  clearMessagesByRoomId(room_id: string) {
    this.messages[room_id] = null;
    // this.messagesChanged.next(Object.assign({}, this.messages));
  }

  findChatRoomById(room_id: string): ChatRoom | null {
    // Для тех групп в табы передавать также и секцию и оттуда берем чат
    // Начинаем с выбранной группы/темы/контакта

    for (const sectionKey of Object.keys(CHAT_SECTIONS)) {
      const chatSectionName = CHAT_SECTIONS[sectionKey].name;
      for (const key of ['contacts', 'groups', 'themes']) {
        const chatRoomById =
          chatSectionName === ChatSectionsEnum.TECH
            ? this[key][chatSectionName]?.[this.currentTechType.getValue()]?.[room_id]
            : this[key][chatSectionName]?.[room_id];

        if (chatRoomById) {
          return Object.assign({}, chatRoomById);
        }
      }
    }

    return null;
  }

  // messages end

  // users
  storeUsers(users: User[]) {
    if (users.length > 1) {
      this.users = {};
    }
    for (const user of users) {
      this.users[user.id] = user;
    }
    this.updateUsersCompanies();
  }

  storeDutyTso(duties: User[]) {
    const isDuty = duties.some((duty) => duty.id === this.userId);
    if (!isDuty) {
      // Обнуляем счетчик для тех групп
      const techCounter = this.techCounter.getValue();
      const allUserCounter = techCounter[TechTypeEnum.ALL_USERS];
      this.widgetMessageCounter -= allUserCounter;
      this.chatSectionService.chatSections[ChatSectionsEnum.TECH].counter -= allUserCounter;
      this.widgetMessageCounterChanged.next(this.widgetMessageCounter);

      this.tabs = this.tabs.filter((tab) => tab?.tech_section_type !== TechTypeEnum.ALL_USERS);
      this.tabsChanged.next(this.tabs);

      techCounter[TechTypeEnum.ALL_USERS] = 0;
      this.techCounter.next(techCounter);
    }
    this.isDutyTso.next(isDuty);
    // При необходимости обновить группы и темы - тут важна последовательность работы сокетов
  }

  setRemovedTsoId(id: number) {
    this.removedTsoId.next(id);
  }

  isCurrentUserDutyTso() {
    return this.isDutyTso.getValue();
  }

  setNewCurrentTechType(value: TechTypeEnum) {
    if (!value) {
      value = null;
    }
    this.currentTechType.next(value);
  }

  isDutyTsoAction() {
    return this.isDutyTso.getValue() && this.currentTechType.getValue() === TechTypeEnum.ALL_USERS;
  }

  isTsoAction() {
    return this.currentTechType.getValue() === TechTypeEnum.MY_USERS;
  }

  // users end

  // contacts
  getContacts(section_name: string = ''): ChatUserTree {
    const sectionName = section_name ? section_name : this.chatSectionService.chatSectionSelected.name;
    this.contacts[sectionName] = this.contacts[sectionName] || {};

    /* -------------------------------------------------------------------------- */
    // user without room
    // серверная оптимизация, контакты сами по себе больше не создаются
    let contacts = this.contacts[sectionName];
    const users: {
      [key: number]: User;
    } = this.users;

    // архивные нужны для модалок
    // for (const user_id of Object.keys(users)) {
    //   if (users[user_id].status === StatusesEnum.IN_ARCHIVE) {
    //     delete users[user_id];
    //   }
    // }

    if (Object.keys(users).length !== Object.keys(contacts).length && sectionName !== ChatSectionsEnum.TECH) {
      const mutateContacts: ChatRoom[] = Object.values(contacts);

      const contactIds = mutateContacts.map((item) => +item.id);

      const usersWithoutRoom = Object.keys(users).filter((item) => !contactIds.includes(+item));

      usersWithoutRoom.forEach((id) => {
        const contactRoomId = `p-${mutateContacts[0]?.id}_${id}`;

        contacts = {
          ...contacts,
          [contactRoomId]: {
            ...users[id],
            room_id: contactRoomId,
            section: { ...mutateContacts[0]?.section, counter: 0 },
          },
        };
      });
    }
    this.contacts[sectionName] = contacts;

    /* -------------------------------------------------------------------------- */
    const searchQuery = this.contactsFilterChanged?.value;

    if (!searchQuery) {
      return Object.assign({}, contacts);
    } else {
      const contactsFiltered: any = {};

      if (sectionName === ChatSectionsEnum.HOLDING) {
        return Object.assign({}, contacts);
      }

      if (sectionName !== ChatSectionsEnum.TECH) {
        for (const contact_id of Object.keys(contacts)) {
          const contact = contacts[contact_id];
          const { second_name } = contact;

          if (second_name.toLowerCase().includes(searchQuery.toLowerCase())) {
            contactsFiltered[contact_id] = Object.assign({}, contact);
          }
        }
      } else {
        Object.keys(contacts).forEach((type_id) => {
          const techGroup = contacts[type_id];
          contactsFiltered[type_id] = {};
          Object.keys(techGroup).forEach((contact_id) => {
            const contact = techGroup[contact_id];
            const { second_name } = contact;

            if (second_name.toLowerCase().includes(searchQuery.toLowerCase())) {
              contactsFiltered[type_id][contact_id] = Object.assign({}, contact);
            }
          });
        });
      }

      return contactsFiltered;
    }
  }

  updateContacts() {
    Object.keys(CHAT_SECTIONS).forEach((section) => {
      const chatSectionName = CHAT_SECTIONS[section].name;

      if (this.rooms[chatSectionName]) {
        Object.keys(this.rooms[chatSectionName]).forEach((userId) => {
          if (this.users[userId] && chatSectionName !== ChatSectionsEnum.TECH) {
            const room = this.rooms[chatSectionName][userId];
            this.contacts[chatSectionName][room.room_id] = this.users[userId];

            const contact = this.contacts[chatSectionName][room.room_id];
            contact.room_id = room.room_id;
            contact.section = CHAT_SECTIONS[section];
          } else if (chatSectionName === ChatSectionsEnum.TECH) {
            // В данном случае контакты уже приходят заполненнные в сокете и в this.users таких контактов не будет
            const room = this.rooms[chatSectionName][userId];
            // Доработать для тех раздела +

            Object.keys(this.contacts[chatSectionName]).forEach((tech_section_type) => {
              const contact = this.contacts[chatSectionName][tech_section_type]?.[room.room_id];
              if (contact) {
                contact.section = CHAT_SECTIONS[section];
              }
            });
          } else {
            const room = this.rooms[chatSectionName][userId];
            // Доработать для тех раздела ?
            delete this.contacts[chatSectionName][room.room_id];
          }
        });
      }
    });

    this.contactsChanged.next(Object.assign({}, this.contacts[this.chatSectionService.chatSectionSelected.name]));

    const chatRoom = this.getActiveRoomByUrl(this.contacts);
    // костыль
    setTimeout(() => {
      if (chatRoom) {
        this.setRoomSelected(chatRoom);
        this.setNewCurrentTechType(chatRoom.tech_section_type);
      }
    }, 0);
  }

  getContactSelected(): ChatRoom {
    return Object.assign({}, this.contactSelected);
  }

  // Удалить неиспользуемые методы. Если используется доработать поиск для тех раздела
  findGroupById(groupId: number, section: ChatSection) {
    const roomID = Object.keys(this.groups[section.name]).find((rId) => this.groups[section.name][rId].id === groupId);

    return this.groups[section.name][roomID];
  }

  setRoomSelected(contactSelected: ChatRoom) {
    this.contactSelected = contactSelected;
    this.contactSelectedChanged.next(Object.assign({}, this.contactSelected));

    // тригерит смену секций у контактов (в поздних этапах удалить если не пригодиться)
    // if (this.chatSectionService.chatSectionSelected.name !== contactSelected.section.name) {
    //   this.setChatSectionSelected(contactSelected.section);
    // }

    if (this.chatSectionService.chatSectionSelected.name === ChatSectionsEnum.TRADE) {
    }

    // разворачивает активную группу но сворачивает все остальные (в поздних этапах удалить если не пригодиться)
    // if (contactSelected.group_id) {
    //   const group = this.findGroupById(contactSelected.group_id, contactSelected.section);

    //   this.groupExpanded.next({
    //     ...group,
    //     isGroupExpanded: true,
    //   });
    // }

    if (this.isTradeGroup(contactSelected)) {
      this.groupSelected = contactSelected;
      this.groupSelectedChanged.next(Object.assign({}, this.groupSelected));
    }

    if (this.isTradeTheme(contactSelected)) {
      this.groupSelected = null;
      this.groupSelectedChanged.next({} as ChatRoom);

      this.themeSelected = contactSelected;
      this.themeSelectedChanged.next(Object.assign({}, this.themeSelected));
    }

    this.updateTabs(contactSelected);
  }

  isTradeGroup(room: ChatRoom) {
    return room && room.section && !room.group_id && !room.second_name && room.section.name === ChatSectionsEnum.TRADE;
  }

  isTradeTheme(room: ChatRoom) {
    return room && room.section && room.group_id && !room.second_name && room.section.name === ChatSectionsEnum.TRADE;
  }

  updateAdminManager(users) {
    for (const userId of users) {
      this.users[userId] = this.users[userId] || ({} as User);
      this.users[userId].isCrownActive = true;
    }
    this.updateUsersCompanies();
  }

  hideAllGroup() {
    this.groupExpanded.next({
      isGroupExpanded: true,
    });
  }

  deleteAdminManager(users) {
    for (const userId of users) {
      this.users[userId] = this.users[userId] || ({} as User);
      this.users[userId].isCrownActive = false;
    }
    this.updateUsersCompanies();
  }

  isAdminManager(userId) {
    this.users[userId] = this.users[userId] || ({} as User);
    return this.users[userId].isCrownActive;
  }

  // contacts end

  // groups
  toggleIsEditingGroupOrTheme() {
    this.isEditingGroupOrTheme = !this.isEditingGroupOrTheme;
    this.isEditingGroupOrThemeChanged.next(this.isEditingGroupOrTheme);
  }

  getGroups(section_name: string = '') {
    const sectionName = section_name ? section_name : this.chatSectionService.chatSectionSelected.name;
    this.groups[sectionName] = this.groups[sectionName] || {};
    return this.groups[sectionName];
  }

  updateGroups(groups: ChatRoom[], section: ChatSection) {
    this.groups[section.name] = this.groups[section.name] || {};
    for (const group of groups) {
      if (
        !(
          group.users.filter((userId) => userId === this.userId).length ||
          group.partner_owner_id === this.userId ||
          group.flags?.is_owner_duty_tso ||
          this.isDutyTso.getValue() ||
          (section.name === ChatSectionsEnum.TECH && group.tech_section_type === TechTypeEnum.ALL_USERS)
        ) &&
        section.name !== ChatSectionsEnum.TRADE
      ) {
        this.removeGroup([group], section);
      } else {
        group.section = section;

        if (section.name !== ChatSectionsEnum.TECH) {
          group.userItems = group.users
            .map((userId) => {
              return this.users[userId];
            })
            .filter((user) => !!user);

          this.groups[section.name][group.room_id] = Object.assign({}, this.groups[section.name][group.room_id], group);
        }

        if (section.name === ChatSectionsEnum.TECH) {
          this.groups[section.name][TechTypeEnum.MY_TECH] = this.groups[section.name][TechTypeEnum.MY_TECH] || {};
          this.groups[section.name][TechTypeEnum.MY_USERS] = this.groups[section.name][TechTypeEnum.MY_USERS] || {};
          this.groups[section.name][TechTypeEnum.ALL_USERS] = this.groups[section.name][TechTypeEnum.ALL_USERS] || {};
          group.userItems = group.users_info;
          delete group.users_info;

          if (group.tech_section_type) {
            this.groups[section.name][group.tech_section_type][group.room_id] = Object.assign(
              {},
              this.groups[section.name][group.tech_section_type][group.room_id],
              group
            );
          }
        }
      }
    }

    this.updateTabsData();
    if (section.name === this.chatSectionService.chatSectionSelected.name) {
      this.groupsChanged.next(this.groups[this.chatSectionService.chatSectionSelected.name]);
    }

    const room = this.getActiveRoomByUrl(this.groups);
    if (room) {
      this.setNewCurrentTechType(room.tech_section_type);
      this.setRoomSelected(room);
    }
  }

  getActiveRoomByUrl(entities): ChatRoom {
    const urlSegments = this.router.url.split('/');
    const roomIdFromUrl = urlSegments[urlSegments.length - 1].replace(')', '');

    const findedSectionName = Object.keys(entities).find((sectionName) => entities[sectionName][roomIdFromUrl]);
    if (!entities[findedSectionName]) {
      return null;
    }
    return entities[findedSectionName][roomIdFromUrl] || null;
  }

  removeGroup(group, section) {
    const sectionName = section.name;

    this.groups[section.name] = this.groups[section.name] || {};
    if (section.name !== ChatSectionsEnum.TECH) {
      delete this.groups[sectionName][group.room_id];
    }
    if (section.name === ChatSectionsEnum.TECH) {
      const counter = this.techCounter.getValue();
      Object.keys(this.groups[section.name]).forEach((tech_section_type) => {
        counter[tech_section_type] -= this.groups[section.name][tech_section_type]?.[group.room_id]?.counter ?? 0;

        delete this.groups[section.name][tech_section_type]?.[group.room_id];
      });

      // При отказе пользователя от ОТП - у ОТП удаляем контакт (приходит событие update_tech_groups)
      counter[TechTypeEnum.MY_USERS] -=
        this.contacts[section.name][TechTypeEnum.MY_USERS]?.[group.room_id]?.counter ?? 0;
      delete this.contacts[section.name]?.[TechTypeEnum.MY_USERS]?.[group.room_id];
      if (section.name === this.chatSectionService.chatSectionSelected.name) {
        this.contactsChanged.next(this.contacts[this.chatSectionService.chatSectionSelected.name]);
      }
      this.techCounter.next({ ...counter });
    }

    this.deleteTab(group);
    delete this.messages[group.room_id];

    if (this.contactSelected.room_id === group.room_id) {
      this.clearContactSelected();
      this.goToRoot();
    }

    this.updateTabsData();
    this.updateWidgetAndSectionMessageCounter();
    if (section.name === this.chatSectionService.chatSectionSelected.name) {
      this.groupsChanged.next(this.groups[this.chatSectionService.chatSectionSelected.name]);
    }
  }

  toggleGroupOpen(group, sectionName) {
    // Удалить неиспользуемые методы
    this.groups[sectionName][group.room_id].isExpanded = !group.isExpanded;
  }

  // groups end

  getGroupsThemes(section_name: string = ''): IGroupsThemes {
    const sectionName = section_name ? section_name : this.chatSectionService.chatSectionSelected.name;
    this.themes[sectionName] = this.themes[sectionName] || {};
    this.groups[sectionName] = this.groups[sectionName] || {};

    const techTypes: string[] = [TechTypeEnum.MY_TECH, TechTypeEnum.MY_USERS, TechTypeEnum.ALL_USERS];

    const searchQuery = this.contactsFilterChanged?.value;

    if (!searchQuery) {
      const defaultGroups = this.groups[sectionName];
      const defaultThemes = this.themes[sectionName];

      Object.keys(defaultThemes).forEach((room_id) => {
        const techThemes = defaultThemes[room_id];
        if (defaultThemes[room_id] && techTypes.includes(room_id)) {
          Object.keys(techThemes).forEach((r_id) => {
            defaultThemes[room_id][r_id] = { ...techThemes[r_id], filterDisabled: false };
          });
        } else {
          defaultThemes[room_id] = { ...techThemes, filterDisabled: false };
        }
      });

      Object.keys(defaultGroups).forEach((room_id) => {
        const techGroups = defaultGroups[room_id];
        if (defaultGroups[room_id] && techTypes.includes(room_id)) {
          Object.keys(techGroups).forEach((r_id) => {
            defaultGroups[room_id][r_id] = { ...techGroups[r_id], filterDisabled: false, isGroupExpanded: false };
          });
        } else {
          defaultGroups[room_id] = { ...techGroups, filterDisabled: false, isGroupExpanded: false };
        }
      });

      return {
        themes: defaultThemes,
        groups: defaultGroups,
      };
    } else {
      const groups = this.groups[sectionName];
      const themes = this.themes[sectionName];

      const themesFiltered: ChatRoom = {} as ChatRoom;
      const groupsFiltered: ChatRoom = {} as ChatRoom;

      switch (sectionName) {
        case ChatSectionsEnum.ADMIN:
        case ChatSectionsEnum.HOLDING: {
          // находим темы
          Object.entries(themes).forEach(([key, theme]: [string, any]) => {
            if (theme['title'] && theme['title'].toLowerCase().includes(searchQuery.toLowerCase())) {
              themesFiltered[key] = { ...theme, filterDisabled: false };
            }
          });

          // находим группы
          Object.entries(groups).forEach(([key, group]: [string, any]) => {
            if (group['title'] && group['title'].toLowerCase().includes(searchQuery.toLowerCase())) {
              groupsFiltered[key] = { ...group, filterDisabled: false };
            }

            // находим группы для найденных тем и не входящих в найденные группы
            Object.keys(themesFiltered).forEach((theme_id) => {
              if (themesFiltered[theme_id]['group_id'] === group['id']) {
                group.filterDisabled = !group['title'].toLowerCase().includes(searchQuery.toLowerCase());
                group.isGroupExpanded = true;
                groupsFiltered[key] = group;
              }
            });
          });

          // Подключаем в найденные группы вложенные темы
          Object.keys(groupsFiltered).forEach((group_id) => {
            const currentGroup = groupsFiltered[group_id];
            if (!currentGroup['filterDisabled']) {
              Object.keys(themes).forEach((theme_id) => {
                const theme = themes[theme_id];
                if (theme['group_id'] === currentGroup['id']) {
                  currentGroup.isGroupExpanded = true;
                  themesFiltered[theme_id] = { ...theme };
                }
              });
            }
          });

          return {
            themes: themesFiltered,
            groups: groupsFiltered,
          };
        }
        case ChatSectionsEnum.TECH: {
          Object.entries(themes).forEach(([key, theme]: [string, any]) => {
            if (Object.keys(theme).length) {
              themesFiltered[key] = {};
              Object.keys(theme).forEach((theme_id) => {
                if (
                  theme[theme_id]['title'] &&
                  theme[theme_id]['title'].toLowerCase().includes(searchQuery.toLowerCase())
                ) {
                  themesFiltered[key][theme_id] = { ...theme[theme_id], filterDisabled: false };
                }
              });
            }
          });

          Object.entries(groups).forEach(([key, group]: [string, any]) => {
            if (Object.keys(group).length) {
              groupsFiltered[key] = {};
              Object.keys(group).forEach((group_id) => {
                if (
                  group[group_id]['title'] &&
                  group[group_id]['title'].toLowerCase().includes(searchQuery.toLowerCase())
                ) {
                  groupsFiltered[key][group_id] = { ...group[group_id], filterDisabled: false };
                }

                Object.keys(themesFiltered).forEach((tech_type_id) => {
                  Object.keys(themesFiltered[tech_type_id]).forEach((searched_theme_id) => {
                    if (themesFiltered[tech_type_id][searched_theme_id]['group_id'] === group[group_id]['id']) {
                      if (!group[group_id]['title'].toLowerCase().includes(searchQuery.toLowerCase())) {
                        group[group_id].filterDisabled = true;
                      }
                      group[group_id].isGroupExpanded = true;
                      groupsFiltered[key][group_id] = group[group_id];
                    }
                  });
                });
              });
            }
          });

          return {
            themes: themesFiltered,
            groups: groupsFiltered,
          };
        }
        case ChatSectionsEnum.TRADE: {
          // Находим темы удовлетворяющие поиску
          Object.entries(themes).forEach(([key, theme]: [string, any]) => {
            if (
              theme['title'] &&
              theme['title'].toLowerCase().includes(searchQuery.toLowerCase()) &&
              theme['title'].toLowerCase() !== 'Все заказчики'.toLowerCase() &&
              theme['title'].toLowerCase() !== 'Все поставщики'.toLowerCase() &&
              theme['title'].toLowerCase() !== 'Все заказчики и поставщики'.toLowerCase()
            ) {
              themesFiltered[key] = { ...theme, filterDisabled: false };
            }
          });

          // находим родительские темы для найденных тем
          Object.entries(themes).forEach(([key, theme]: [string, any]) => {
            Object.keys(themesFiltered).forEach((theme_id) => {
              if (themesFiltered[theme_id]['group_id'] === theme['id']) {
                this.addTradeExpanded(theme?.room_id);
                themesFiltered[key] = { ...theme, filterDisabled: true };
              }
            });
          });

          // находим группы в которые найденные темы входят
          Object.entries(groups).forEach(([key, group]: [string, any]) => {
            Object.keys(themesFiltered).forEach((theme_id) => {
              if (themesFiltered[theme_id]['group_id'] === group['id']) {
                group.filterDisabled = true;
                group.isGroupExpanded = true;
                groupsFiltered[key] = group;
              }
            });
          });

          // ищем группы которые подходят под условия поиска
          Object.entries(groups).forEach(([key, group]: [string, any]) => {
            if (group['title'] && group['title'].toLowerCase().includes(searchQuery.toLowerCase())) {
              group.filterDisabled = false;
              group.isGroupExpanded = true;
              groupsFiltered[key] = group;
            }
          });

          // У найденных групп показываем все вложенные темы
          Object.keys(groupsFiltered).forEach((group_id) => {
            const currentGroup = groupsFiltered[group_id];
            if (!currentGroup['filterDisabled']) {
              Object.keys(themes).forEach((theme_id) => {
                const firstLevelTheme = themes[theme_id];
                if (firstLevelTheme['group_id'] === currentGroup['id']) {
                  themesFiltered[theme_id] = { ...firstLevelTheme };
                  Object.keys(themes).forEach((theme_children_id) => {
                    const secondLevelTheme = themes[theme_children_id];
                    if (firstLevelTheme['id'] === secondLevelTheme['group_id']) {
                      this.addTradeExpanded(firstLevelTheme['room_id']);
                      themesFiltered[theme_children_id] = { ...secondLevelTheme };
                    }
                  });
                }
              });
            }
          });

          return {
            themes: themesFiltered,
            groups: groupsFiltered,
          };
        }
      }
    }
  }

  // themes
  getThemes(section_name: string = '') {
    const sectionName = section_name ? section_name : this.chatSectionService.chatSectionSelected.name;
    this.themes[sectionName] = this.themes[sectionName] || {};
    return Object.assign({}, this.themes[sectionName]);
  }

  updateThemes(themes, section) {
    this.themes[section.name] = this.themes[section.name] || {};

    for (const theme of themes) {
      if (!theme.users.includes(theme.owner_id)) {
        theme.users.push(theme.owner_id);
      }
      if (
        !(
          theme.users.filter((userId) => userId === this.userId).length ||
          theme.partner_owner_id === this.userId ||
          theme.flags?.is_owner_duty_tso ||
          this.isDutyTso.getValue() ||
          (section.name === ChatSectionsEnum.TECH && theme.tech_section_type === TechTypeEnum.ALL_USERS)
        ) &&
        section.name !== ChatSectionsEnum.TRADE
        // проверка на трейд фиксит обновление имени у поставщика тк
        // theme.users.filter выдает false (после обновления имени users у темы приходит пустой)
      ) {
        this.removeTheme([theme], section);
      } else {
        theme.section = section;

        if (section.name !== ChatSectionsEnum.TECH) {
          theme.userItems = theme.users.map((userId: number) => {
            return this.users[userId];
          });
          this.themes[section.name][theme.room_id] = Object.assign({}, this.themes[section.name][theme.room_id], theme);
        }
        if (section.name === ChatSectionsEnum.TECH) {
          this.themes[section.name][TechTypeEnum.MY_TECH] = this.themes[section.name][TechTypeEnum.MY_TECH] || {};
          this.themes[section.name][TechTypeEnum.MY_USERS] = this.themes[section.name][TechTypeEnum.MY_USERS] || {};
          this.themes[section.name][TechTypeEnum.ALL_USERS] = this.themes[section.name][TechTypeEnum.ALL_USERS] || {};
          theme.userItems = theme.users_info;
          delete theme.users_info;

          if (theme.tech_section_type) {
            this.themes[section.name][theme.tech_section_type][theme.room_id] = Object.assign(
              {},
              this.themes[section.name][theme.tech_section_type][theme.room_id],
              theme
            );
          }
        }
      }
    }

    this.updateTabsData();
    if (section.name === this.chatSectionService.chatSectionSelected.name) {
      this.themesChanged.next(Object.assign({}, this.themes[this.chatSectionService.chatSectionSelected.name]));
    }

    const room = this.getActiveRoomByUrl(this.themes);
    if (room) {
      this.setNewCurrentTechType(room.tech_section_type);
      this.setRoomSelected(room);
    }
  }

  removeTheme(theme, section) {
    this.themes[section.name] = this.themes[section.name] || {};
    if (section.name !== ChatSectionsEnum.TECH) {
      delete this.themes[section.name][theme.room_id];
    }
    if (section.name === ChatSectionsEnum.TECH) {
      Object.keys(this.themes[section.name]).forEach((tech_section_type) => {
        const counter = this.techCounter.getValue();
        counter[tech_section_type] -= this.themes[section.name][tech_section_type]?.[theme.room_id]?.counter ?? 0;
        this.techCounter.next({ ...counter });
        delete this.themes[section.name][tech_section_type]?.[theme.room_id];
      });
    }

    this.deleteTab(theme);
    delete this.messages[theme.room_id];

    if (this.contactSelected.room_id === theme.room_id) {
      this.clearContactSelected();
      this.goToRoot();
    }

    this.updateTabsData();
    this.updateWidgetAndSectionMessageCounter();
    if (section.name === this.chatSectionService.chatSectionSelected.name) {
      this.themesChanged.next(Object.assign({}, this.themes[this.chatSectionService.chatSectionSelected.name]));
    }
  }

  toggleThemeOpen(theme, sectionName) {
    // Удалить неуспользуемые методы
    this.themes[sectionName][theme.room_id].isExpanded = !theme.isExpanded;
  }

  // themes end

  // rooms
  storeRooms(roomsData: SocketResponseRoomData[], section: ChatSection) {
    if (section.name !== ChatSectionsEnum.TECH && roomsData.length > 1) {
      this.contacts[section.name] = null;
    }

    this.rooms[section.name] = this.rooms[section.name] || {};
    for (const room of roomsData) {
      this.rooms[section.name][room.user_id] = { room_id: room.room_id };
      if (section.name === ChatSectionsEnum.TECH) {
        const { owner, ...rest } = room;
        this.contacts[section.name] = this.contacts[section.name] || {};
        this.contacts[section.name][TechTypeEnum.MY_TECH] = this.contacts[section.name][TechTypeEnum.MY_TECH] || {};
        this.contacts[section.name][TechTypeEnum.MY_USERS] = this.contacts[section.name][TechTypeEnum.MY_USERS] || {};
        this.contacts[section.name][TechTypeEnum.ALL_USERS] = this.contacts[section.name][TechTypeEnum.ALL_USERS] || {};

        // Тут по идее может отличаться только время обновления контакта - дежурному сообщение приходит позже
        switch (room.tech_section_type) {
          case TechTypeEnum.MY_TECH:
            this.contacts[section.name][TechTypeEnum.MY_TECH][room.room_id] = { ...owner, ...rest };
            break;
          case TechTypeEnum.MY_USERS:
            this.contacts[section.name][TechTypeEnum.MY_USERS][room.room_id] = { ...owner, ...rest };
            break;
          case TechTypeEnum.ALL_USERS:
            this.contacts[section.name][TechTypeEnum.ALL_USERS][room.room_id] = { ...owner, ...rest };
            break;
        }
      }
    }

    this.setRooms();
  }

  setRooms() {
    Object.keys(CHAT_SECTIONS).forEach((section) => {
      const chatSectionName = CHAT_SECTIONS[section].name;
      this.contacts[chatSectionName] = this.contacts[chatSectionName] || {};

      if (this.rooms[chatSectionName]) {
        Object.keys(this.rooms[chatSectionName]).forEach((userId) => {
          const room = this.rooms[chatSectionName][userId];
          if (chatSectionName !== ChatSectionsEnum.TECH) {
            this.contacts[chatSectionName][room.room_id] = this.contacts[chatSectionName][room.room_id] || {};

            const contact = this.contacts[chatSectionName][room.room_id];
            contact.room_id = room.room_id;
            contact.id = userId;
          }
        });
      }
    });
    this.updateContacts();
  }

  // rooms end

  // companies
  storeCompanies(companies: Company[]) {
    for (const company of companies) {
      this.companies[company.id] = company;
    }
    this.updateUsersCompanies();
  }

  updateUsersCompanies() {
    for (const id in this.users) {
      if (this.users[id].company_id) {
        this.users[id].company = this.companies[this.users[id].company_id] || this.users[id].company;
      }
    }
    this.updateContacts();
    Object.keys(CHAT_SECTIONS).forEach((section) => {
      const chatSectionName = CHAT_SECTIONS[section].name;
      this.groups[chatSectionName] = this.groups[chatSectionName] || {};
      this.themes[chatSectionName] = this.themes[chatSectionName] || {};
      Object.keys(this.themes[chatSectionName]).forEach((roomId) => {
        if (this.themes[chatSectionName][roomId] && chatSectionName !== ChatSectionsEnum.TECH) {
          this.themes[chatSectionName][roomId].userItems = this.themes[chatSectionName][roomId].userItems || [];
          this.themes[chatSectionName][roomId].userItems = this.themes[chatSectionName][roomId].userItems.map(
            (user) => this.users[user?.id]
          );
        }
      });
      Object.keys(this.groups[chatSectionName]).forEach((roomId) => {
        if (this.groups[chatSectionName][roomId] && chatSectionName !== ChatSectionsEnum.TECH) {
          this.groups[chatSectionName][roomId].userItems = this.groups[chatSectionName][roomId].userItems || [];
          this.groups[chatSectionName][roomId].userItems = this.groups[chatSectionName][roomId].userItems.map(
            (user) => this.users[user?.id]
          );
        }
      });
    });
    this.usersChanged.next(this.users);
  }

  goToRoot() {
    this.openChat();
  }

  tryOpenChatByUserId(userId: number) {
    this.goToRoot();

    if (this.chatLoaded.getValue()) {
      const roomId = this.openRoom(userId);

      if (!roomId) {
        this.notify.warn('Чат', 'Не удалось определить раздел чата, пожалуйста обратитесь к администратору');
      }
      return;
    }

    // разобраться с потоками при редизайне чата
    this.chatLoaded
      .pipe(
        delay(100),
        filter((value) => value)
      )
      .subscribe(() => {
        const roomId = this.openRoom(userId);

        if (!roomId) {
          this.notify.warn('Чат', 'Не удалось определить раздел чата, пожалуйста обратитесь к администратору');
        }
      });
  }

  openRoom(userId: number) {
    const roomId = this.getRoomIdByUserId(userId);
    if (!!roomId) {
      this.openChat(roomId);

      const room = this.getActiveRoomByUrl(this.contacts) || this.getActiveRoomByUrl(this.groups);

      this.setNewCurrentTechType(room.tech_section_type);
      this.setRoomSelected(room);
    }

    return roomId;
  }

  // надо бы сделать приватным
  getRoomIdByUserId(userId: number): string {
    let roomId: string;
    Object.keys(this.contacts).forEach((sectionKey) => {
      // Доработать для тех раздела - если если нужен поиск по тех контактам
      Object.keys(this.contacts[sectionKey]).forEach((roomIdKey) => {
        if (this.contacts[sectionKey][roomIdKey] && +this.contacts[sectionKey][roomIdKey].id === +userId) {
          roomId = roomIdKey;
        }
      });
    });

    return roomId;
  }

  getRoomIdByTradeId(tradeId): string {
    let roomId: string;

    if (!this.groups.trade) {
      return roomId;
    }

    Object.keys(this.groups.trade).forEach((roomIdKey) => {
      if (this.groups.trade[roomIdKey] && +this.groups.trade[roomIdKey].trade_id === +tradeId) {
        roomId = roomIdKey;
      }
    });

    return roomId;
  }

  // companies end

  totalUnreadCount(data: ChatRoom[]) {
    const count = data.reduce((acc, item) => acc + (item?.counter || 0), 0);
    return count <= 10 ? count : '10+';
  }

  // user tree

  storeUserTree(userTree: ChatUserTree) {
    this.userTree = userTree;
    this.userTreeChanged.next();
  }

  getUserTree() {
    return this.userTree;
  }

  getUserTreeFiltered(): ChatUserTree | null {
    const searchQuery = this.contactsFilterChanged?.value;

    if (!searchQuery?.length) {
      return this.userTree;
    } else {
      const userTree = deepCopy(this.userTree);
      let isSearchResult = false;

      const filterTree = (users: ChatUserTree[], search: string) => {
        return users?.reduce((acc, user) => {
          const { second_name } = user;
          const matchesCurrentNode = second_name.toLowerCase().includes(search.toLowerCase());

          const filteredChildren = user?.children?.length ? filterTree(user.children, search) : [];

          this.addUserExpanded(Number(user?.id));
          if (matchesCurrentNode || filteredChildren?.length > 0) {
            isSearchResult = true;
            acc.push({
              ...user,
              children: filteredChildren,
              filterDisabled: !matchesCurrentNode,
            });
          }

          return acc;
        }, []);
      };

      userTree.filterDisabled = !userTree?.second_name.toLowerCase().includes(searchQuery.toLowerCase()) ?? false;
      userTree.children = filterTree(deepCopy(this.userTree)?.children, searchQuery);

      if (userTree) {
        this.addUserExpanded(Number(userTree?.id));
      }

      return isSearchResult ? userTree ?? {} : {};
    }
  }

  public resetChatState() {
    // Очищаем данные (state)
    this.contacts = {};
    this.groups = {};
    this.themes = {};
    this.messages = {};
    this.companies = {};
    this.rooms = {};
    this.users = {};
    this.userTree = null;
    this.chatSectionService.setChatSectionSelected(CHAT_SECTIONS.holding);
    this.chatSectionService.chatSections = { ...CHAT_SECTIONS };

    // Сбрасываем выбранные элементы
    this.contactSelected = { id: null } as ChatRoom;
    this.groupSelected = { id: null } as ChatRoom;
    this.themeSelected = { id: null } as ChatRoom;
    this.editingGroupOrThemeObject = {} as ChatRoom;
    this.tabs = [];

    // Сбрасываем фильтры
    this.contactsFilter = '';
    this.setContactsOnlyFilter(null);
    this.contactsOnlyFiltersState$.next({});
    this.isEditingContactsOnlyFilter = false;
    this.isEditingGroupOrTheme = false;
    this.isTabsInit = true;

    // Сбрасываем счетчики
    this.widgetMessageCounter = 0;

    // Уведомляем подписчиков
    this.contactsChanged.next(this.contacts);
    this.groupsChanged.next(this.groups);
    this.themesChanged.next(this.themes);
    this.messagesChanged.next(this.messages);
    this.usersChanged.next(this.users);
    this.widgetMessageCounterChanged.next(this.widgetMessageCounter);
    this.contactsOnlyFilterChanged.next(this.contactsOnlyFilter);
    this.contactSelectedChanged.next(this.contactSelected);
    this.groupSelectedChanged.next(this.groupSelected);
    this.themeSelectedChanged.next(this.themeSelected);
    this.isEditingContactsOnlyFilterChanged.next(this.isEditingContactsOnlyFilter);
    this.isEditingGroupOrThemeChanged.next(this.isEditingGroupOrTheme);
    // this.chatSectionSelectedChanged.next(this.chatSectionSelected);
    this.techCounter.next({
      [TechTypeEnum.MY_TECH]: 0,
      [TechTypeEnum.MY_USERS]: 0,
      [TechTypeEnum.ALL_USERS]: 0,
    });
  }

  isUserExpanded(id: number) {
    return this.userTreeExpanded.includes(id);
  }

  addUserExpanded(id: number) {
    this.userTreeExpanded.push(id);
  }

  removeUserExpanded(id: number) {
    this.userTreeExpanded = this.userTreeExpanded.filter((item) => item !== id);
  }

  isTradeExpanded(room_id: string) {
    return this.tradeExpanded.includes(room_id);
  }

  addTradeExpanded(room_id: string) {
    this.tradeExpanded.push(room_id);
  }

  removeTradeExpanded(room_id: string) {
    this.tradeExpanded = this.tradeExpanded.filter((item) => item !== room_id);
  }

  private findLastMessageThemeDate(groups: ChatRoom[], themes: ChatRoom[]): ChatLastMessageDateThemeInGroups[] {
    if (this.chatSectionService.chatSectionSelected.name === ChatSectionsEnum.TRADE) {
      return groups.map((item) => {
        const findThemes = [...themes].filter((theme) => theme.group_id === item.id);
        findThemes.map((findTheme) => {
          const nestedThemes = [...themes].filter((theme) => theme.group_id === findTheme.id);
          if (nestedThemes.length) {
            nestedThemes.map((nestedTheme) => findThemes.push(nestedTheme));
          }
        });
        const sortThemes = this.sortRoomsByMessageActivity(findThemes);
        return {
          id: item.id,
          group_id: sortThemes[0]?.group_id,
          owner_id: sortThemes[0]?.owner_id,
          type: sortThemes[0]?.type,
          theme_last_reed_message_date: sortThemes[0]?.last_read_message_date_read,
          first_not_read_theme_message_creation_date_in_chat:
            sortThemes[0]?.first_not_read_message_creation_date_in_chat,
          created_at: sortThemes[0]?.created_at,
        };
      });
      return;
    } else {
      return groups.map((item) => {
        const findThemes = [...themes].filter((theme) => theme.group_id === item.id);
        const sortThemes = this.sortRoomsByMessageActivity(findThemes);
        return {
          id: item.id,
          theme_last_reed_message_date: sortThemes[0]?.last_read_message_date_read,
          first_not_read_theme_message_creation_date_in_chat:
            sortThemes[0]?.first_not_read_message_creation_date_in_chat,
          created_at: sortThemes[0]?.created_at,
        };
      });
    }
  }

  sortGroupRooms({
    userId,
    newOrder,
    rooms,
    childrenRooms,
  }: {
    userId: User['id'];
    newOrder: GroupSortingNames;
    rooms: ChatRoom[];
    childrenRooms?: ChatRoom[];
  }): ChatRoom[] {
    switch (newOrder) {
      case GroupSortingNames.TITLE: {
        return this.sortRoomsByAlhabet(rooms);
      }
      case GroupSortingNames.OWNER: {
        const lastMessageThemeDate = this.findLastMessageThemeDate(rooms, childrenRooms || []);
        return this.sortGroupsByOwner(rooms, lastMessageThemeDate, userId);
      }
      case GroupSortingNames.LAST_MESSAGE_DATE:
      default: {
        // console.log(childrenRooms);
        const lastMessageThemeDate = this.findLastMessageThemeDate(rooms, childrenRooms || []);
        return this.sortRoomsByMessageActivity(rooms, lastMessageThemeDate);
      }
    }
  }

  private sortRoomsByMessageActivity(data: ChatRoom[], themesDate?: ChatLastMessageDateThemeInGroups[]) {
    const unread: ChatRoom[] = [];
    const read: ChatRoom[] = [];
    data.map((item) => {
      const themeNotRead = themesDate?.find(
        (theme) => theme.id === item.id
      )?.first_not_read_theme_message_creation_date_in_chat;
      if (item.first_not_read_message_creation_date_in_chat || themeNotRead) {
        unread.push(item);
      } else {
        read.push(item);
      }
    });

    unread.sort((a, b) => {
      const firstNotReadTheme =
        themesDate?.find((item) => +item.id === +a.id)?.first_not_read_theme_message_creation_date_in_chat || null;
      const secondNotReadTheme =
        themesDate?.find((item) => +item.id === +b.id)?.first_not_read_theme_message_creation_date_in_chat || null;

      const firstNotRead = a.first_not_read_message_creation_date_in_chat || now();
      const secondNotRead = b.first_not_read_message_creation_date_in_chat || now();

      const firstDate =
        firstNotReadTheme && new Date(firstNotReadTheme) < new Date(firstNotRead)
          ? new Date(firstNotReadTheme).getTime()
          : new Date(firstNotRead).getTime();

      const secondDate =
        secondNotReadTheme && new Date(secondNotReadTheme) < new Date(secondNotRead)
          ? new Date(secondNotReadTheme).getTime()
          : new Date(secondNotRead).getTime();

      return firstDate - secondDate;
    });

    read.sort((a, b) => {
      const firstTheme = themesDate?.find((item) => +item.id === +b.id);
      const secondTheme = themesDate?.find((item) => +item.id === +a.id);
      const firstLastReadTheme = firstTheme?.theme_last_reed_message_date
        ? firstTheme?.theme_last_reed_message_date
        : firstTheme?.created_at;
      const secondLastReadTheme = secondTheme?.theme_last_reed_message_date
        ? secondTheme?.theme_last_reed_message_date
        : secondTheme?.created_at;

      const firstLastCreation = b.last_read_message_date_read ? b.last_read_message_date_read : b.created_at;
      const secondLastCreation = a.last_read_message_date_read ? a.last_read_message_date_read : a.created_at;

      const firstLastMessageDate =
        new Date(firstLastReadTheme).getTime() > new Date(firstLastCreation).getTime()
          ? new Date(firstLastReadTheme).getTime()
          : new Date(firstLastCreation).getTime();

      const secondLastMessageDate =
        new Date(secondLastReadTheme).getTime() > new Date(secondLastCreation).getTime()
          ? new Date(secondLastReadTheme).getTime()
          : new Date(secondLastCreation).getTime();

      return firstLastMessageDate - secondLastMessageDate;
    });

    return [...unread, ...read];
  }

  private sortRoomsByAlhabet(data: ChatRoom[]): ChatRoom[] {
    const numberRegexp = /^\d/;
    const specialCharacterRegexp = /^[^a-zA-Zа-яА-Я\d]/;

    return [...data].sort((a, b) => {
      const isNumberA = numberRegexp.test(a.title);
      const isNumberB = numberRegexp.test(b.title);
      const isSpecialA = specialCharacterRegexp.test(a.title);
      const isSpecialB = specialCharacterRegexp.test(b.title);

      if (isNumberA && isNumberB) {
        return a.title.localeCompare(b.title, 'en', { numeric: true });
      } else if (isNumberA) {
        return -1;
      } else if (isNumberB) {
        return 1;
      }

      if (isSpecialA && isSpecialB) {
        return +new Date(a.created_at) - +new Date(b.created_at);
      } else if (isSpecialA) {
        return -1;
      } else if (isSpecialB) {
        return 1;
      }

      return (
        a.title.localeCompare(b.title, 'ru', { usage: 'sort' }) ||
        a.title.localeCompare(b.title, 'en', { usage: 'sort' })
      );
    });
  }

  private sortGroupsByOwner(
    groups: ChatRoom[],
    themesDate: ChatLastMessageDateThemeInGroups[],
    userId: number
  ): ChatRoom[] {
    let ownerGroups: ChatRoom[];
    let otherGroups: ChatRoom[];

    if (this.chatSectionService.chatSectionSelected.name !== ChatSectionsEnum.TRADE) {
      ownerGroups = groups.filter((group) => group.owner_id === userId);
      otherGroups = groups.filter((group) => group.owner_id !== userId);
    } else {
      ownerGroups = groups.filter((group) => group.owner_id === userId || this.isDeepOwner(group, themesDate, userId));
      otherGroups = groups.filter((group) => group.owner_id !== userId && !this.isDeepOwner(group, themesDate, userId));
    }

    const sortedOwnerGroups = this.sortRoomsByMessageActivity(ownerGroups, themesDate);
    const sortedOtherGroups = this.sortRoomsByMessageActivity(otherGroups, themesDate);

    return [...sortedOwnerGroups, ...sortedOtherGroups];
  }

  isDeepOwner(group: ChatRoom, themesDate: ChatLastMessageDateThemeInGroups[], userId: number) {
    const themes: ChatRoom[] = Object.values(this.themes.trade);
    const findTheme = themes.find((theme) => +theme.group_id === group.id && theme?.type === TradeRoomsTypes.PROVIDER);

    return +findTheme?.owner_id === +userId;
  }

  sortContactsRooms({ newOrder, rooms }: { newOrder: string; rooms: ChatRoom[] }): ChatRoom[] {
    switch (newOrder) {
      case ContactsSortingNames.ROLE: {
        return this.sortContactsByRole(rooms);
      }
      case ContactsSortingNames.NAME:
      default: {
        return this.sortContactsByAlphabet(rooms);
      }
    }
  }

  private sortContactsByRole(contacts: ChatRoom[]): ChatRoom[] {
    const selfId = this.authService?.user_id;
    const sorted = [...contacts]
      .filter((cont) => cont?.type)
      .sort((a, b) => {
        const firstComparison = a.type.trimStart().toLowerCase().localeCompare(b.type.trimStart().toLowerCase(), 'en');
        if (firstComparison !== 0) {
          return firstComparison;
        }
        return a.second_name
          ?.trimStart()
          ?.toLowerCase()
          ?.localeCompare(b.second_name?.trimStart()?.toLowerCase(), 'en');
      });

    // return [sorted.find((item) => +item?.id === +selfId), ...sorted.filter((item) => +item?.id !== +selfId)];
    const selfContact = sorted.find((item) => +item?.id === +selfId);
    const otherContacts = sorted.filter((item) => +item?.id !== +selfId);

    return selfContact ? [selfContact, ...otherContacts] : otherContacts;
  }

  private sortContactsByAlphabet(contacts: ChatRoom[] = []): ChatRoom[] {
    return [...contacts]?.sort((a, b) => {
      return a.second_name?.trimStart()?.toLowerCase()?.localeCompare(b.second_name?.trimStart()?.toLowerCase(), 'en');
    });
  }

  toggleCollapse() {
    this.isCollapsed$.next(!this.isCollapsed$.value);
  }

  private savePinnedTabsToLocalStorage() {
    const userId = this.authService.user_id;

    const key = `${PINNED_TABS_STORAGE_KEY}_${userId}`;

    const pinnedRoomIds = this.pinnedTabs$.value.map((tab) => ({
      room_id: tab.room_id,
      tech_section_type: tab.tech_section_type,
    }));

    this.localStorageService.setItem(key, pinnedRoomIds);
  }

  private loadPinnedTabsFromLocalStorage() {
    const userId = this.authService.user_id;

    const key = `${PINNED_TABS_STORAGE_KEY}_${userId}`;

    const roomIds = this.localStorageService.getItem(key) as {
      room_id: string;
      tech_section_type: TechTypeEnum;
    }[];

    if (!roomIds) return;

    const pinnedRooms = roomIds
      .map((obj) => {
        if (obj.tech_section_type) {
          this.currentTechType.next(obj.tech_section_type);
        }

        const room = this.findChatRoomById(obj.room_id);

        return room;
      })
      .filter((tab) => tab && tab.room_id);

    this.currentTechType.next(null);

    this.pinnedTabs$.next(pinnedRooms);
  }

  private updateLocalStorageChatState() {
    this.localStorageService.setItem(CHAT_STATE_STORAGE_KEY, {
      isOpen: this.isChatOpen$.value,
      roomId: this.getContactSelected().room_id,
      currentTechType: this.currentTechType.value,
    });
  }

  private saveContactsFiltersToLocalStorage() {
    const userId = this.authService.user_id;

    const key = `${CONTACTS_FILTER_STORAGE_KEY}_${userId}`;

    const filtersToLocalStorage = Object.entries(this.contactsOnlyFilters)
      .map(([name, filter]) => ({ name, filter }))
      .filter((data) => data.filter && Object.values(ChatSectionsEnum).includes(data.name as ChatSectionsEnum));

    this.localStorageService.setItem(key, filtersToLocalStorage);
  }

  private loadContactsFiltersFromLocalStorage() {
    const userId = this.authService.user_id;

    const key = `${CONTACTS_FILTER_STORAGE_KEY}_${userId}`;

    const filters = this.localStorageService.getItem(key) as { filter: ChatContactsOnlyFilterData; name: string }[];

    if (filters && filters.length) {
      filters.forEach((filterData) => {
        this.setContactsOnlyFilter(filterData.filter, filterData.name);
      });
    }
  }
}
